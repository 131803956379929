import React from "react"

import Layout from "@src/components/Layout"
import Row from "../../V2/components/Row/Row"
import ImageRow from "../../V2/Features/components/ImageRow/ImageRow"
import Button from "../../../components/Button/Button"
import getAppUrl from "@src/utils/getAppUrl"
import DotBG from "../../V2/components/DotBG/DotBG"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm/CalloutWithDemoForm"
import Column from "../../V2/components/Column/Column"
import Splash from "../../V2/Features/components/Splash"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import HeaderImage from "@src/icons/industries/financial_header.png"
import SectionOne from "@src/icons/industries/financial_section_one.png"
import SectionTwo from "@src/icons/industries/financial_section_two.png"
import SectionThree from "@src/icons/industries/financial_section_three.png"
import SectionFour from "@src/icons/industries/financial_section_four.png"
import CardOne from "@src/icons/industries/financial_card_one.png"
import CardTwo from "@src/icons/industries/financial_card_two.png"
import CardThree from "@src/icons/industries/financial_card_three.png"
import OG from "@src/icons/og/OG_Financial.jpg"
import * as styles from "./Financial.module.scss"

const Financial = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()

  return (
    <Layout
      title="Upgrade your Financial services with Canonic "
      description="Streamline Your Operations and Enhance Customer Experiences for Financial Services"
      url="https://canonic.dev/industries/financial-services"
      ogImage={OG}
      className={styles.retail}
    >
      <Splash
        title="Upgrade your Financial services with Canonic "
        subtitle="Streamline Your Operations and Enhance Customer Experiences for Financial Services"
        illustration={<img src={HeaderImage} />}
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless marginLeft className={styles.retailCtaOutline}>
              Get a Demo
            </Button>
          </a>,
        ]}
        reverseOnMobile
        className={styles.retailSplash}
      />
      <Row>
        <div className={styles.retailSolution}>
          <h2>The Need of No-code for Financial Services</h2>
          <p>
            In today's fast-paced digital world, financial services
            organizations must stay ahead of the competition by creating
            innovative and user-friendly web applications that can streamline
            their operations and improve customer experiences.
          </p>
          <p>
            However, building and launching such applications and maintaining
            the financial services can be a daunting and time-consuming task,
            especially for those without extensive coding skills.
          </p>
          <p>
            With Canonic for Financial Services, finance professionals can
            create web applications quickly and easily, without the need for
            specialized programming knowledge. Our intuitive drag-and-drop
            interface allows users to assemble functional web applications in a
            matter of minutes, saving time and resources that can be better
            spent on other critical business operations.
          </p>
        </div>
      </Row>
      <section>
        <Row className={styles.retailProcess}>
          <h2>Making Financial Services Simple and Hustle-free </h2>
          <ImageRow
            title="Customizable Templates"
            subtitle="Canonic offers a drag-and-drop interface and a wide range of customizable templates for various financial services, enabling users to easily create unique and engaging applications with an intuitive and user-friendly interface that meets their business needs."
            screenshot={SectionOne}
            borderless
            className={styles.retailProcessSources}
          />
          <ImageRow
            title="Easy Integration"
            subtitle="Canonic seamlessly integrates with popular tools and services, such as CRM systems, payment gateways, and marketing automation tools, making it easier for users to manage and streamline their business operations."
            screenshot={SectionTwo}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
          <ImageRow
            title="Real-time collaboration"
            subtitle="Users can collaborate with their team members in real-time, enabling them to work on their applications simultaneously and make quick decisions."
            screenshot={SectionThree}
            borderless
            className={styles.retailTailorMade}
          />
          <ImageRow
            title="Security"
            subtitle="Built with the latest security measures, Canonic ensures that all user data and information remain safe and secure at all times."
            screenshot={SectionFour}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
        </Row>
      </section>

      <section>
        <Row>
          <h1 className={styles.retailCardsTitle}>
            Maximize the Potential for Financial Services
          </h1>
          <div className={styles.retailCards}>
            <Column.Wrap>
              <Column
                title="Increased productivity"
                icon={CardOne}
                description="Canonic allows finance professionals to build web applications faster and more efficiently, freeing up time and resources that can be used on other critical business operations."
              />
              <Column
                title="Customizability"
                icon={CardTwo}
                description={
                  "With wide range of customizable templates and design tools, finance professionals can create unique and engaging web applications that meet their specific business needs."
                }
              />
              <Column
                title="Improved Experiences"
                icon={CardThree}
                description={
                  "No-code platforms offer intuitive and user-friendly interfaces, making it easier for customers to use financial services applications and improving overall user experiences."
                }
              />
            </Column.Wrap>
          </div>
        </Row>
      </section>
      <CalloutWithDemoForm originPage="Canonic for Financial" id="get-a-demo" />
    </Layout>
  )
}

export default Financial
